import * as React from 'react'

//import {Link} from 'gatsby'
import Layout from '../components/layout/layout'
//import {StaticImage} from 'gatsby-plugin-image'


const pageTitle = "International Management Association IMA :: About Us"

const AboutPage = () => {
    return (
        <Layout pageTitle={pageTitle}>
            <div className="span-23 last" style={{ overflow : "hidden", marginBottom: "60px", marginTop: "10px" }}>
                <div className="span-1">&nbsp;</div>
                <div className="span-22 last" id="breadcrumb">
                    <a href="/">Home</a> &gt; About IMA
                </div>
                <div className="span-1">&nbsp;</div>
                <div className="span-6">

                    <img src="/lookandfeel/images/titles/about_ima.gif" border="0" alt="About IMA"
                         style={{marginBottom: "20px"}}/>

                    <img src="/lookandfeel/images/photos/about_ima_4.jpg" border="0" style={{marginBottom: "20px"}}/>
                    <img src="/lookandfeel/images/photos/about_ima_1.jpg" border="0"
                         style={{marginBottom: "20px"}}/>


                </div>
                <div className="span-15">
                    <p className="darkpurple"><b>WELCOME TO IMA</b></p>

                    <p>International Management Association (IMA) was established in 1999 and is specialized in
                        providing professional education and services to adult learners and companies in the
                        territory.</p>

                    <p>Being an advocate of education and life-long learning, IMA was established with
                        the mission to promote the development and the application of knowledge, and
                        the maturation of intellect and character through academic and professional
                        training programs. </p>

                    <p>IMA works with renowned universities to encourage intellectual and professional development, to
                        foster wide range of knowledge, concepts and ideas. It also provides facilities and services to
                        help arrange and promote social events and seminars across different industries, sharing
                        different experiences to enhance both personal and professional growth of working
                        professionals. </p>

                    <p>At IMA, all our staff are well-trained and experienced in the education service
                        market. We provide the most professional academic advice and services. We
                        strive to balance the work, study, and other commitments of our working executive
                        students. </p>

                    <p>IMA is honored to be recognized as a Caring Company in Hong Kong, and at the
                        same time receives the Living Business Award from HSBC. We carry the
                        objective of being one of the finest international providers of higher education and
                        professional learning! </p>

                    <p>&nbsp;</p>


                </div>
                <div className="span-1 last">&nbsp;</div>

            </div>
        </Layout>
    )
}
// Step 3: Export your component
export default AboutPage
